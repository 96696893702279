import { useContext } from 'react'

import screenSizeContext from '../context/screenSizeContext'

import { ScreenSize } from '../constants/media-constanst'

const usePCWindow = (key: string = ScreenSize.standart) => {
  const { compare } = useContext(screenSizeContext)
  // let screens: string[] = []
  // if (key === ScreenSize.big) screens = PCScreens
  // if (key === ScreenSize.standart) screens = bigScreens
  // if (key === ScreenSize.mobile) screens = mobileScreens
  // return screens.includes(windowSize)

  return compare[key]
}

export default usePCWindow
